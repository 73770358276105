<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>解决方案</el-breadcrumb-item>
                    <el-breadcrumb-item>头部链接</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_headerSecondary">
                <div></div>
                <div class="_add">
                    <button @click="openAdd()">添加</button>
                </div>
            </div>
            <!-- 列表 -->
            <div class="page_content">
                <el-table :data="tableData" border>
                    <el-table-column prop="title" align="center" label="名字"> </el-table-column>
                    <el-table-column prop="intro" align="center" label="简介"> </el-table-column>
                    <el-table-column prop="url" align="center" label="链接"> </el-table-column>
                    <el-table-column prop="" align="center" label="操作">
                        <template slot-scope="{ row }">
                            <div class="btns">
                                <div class="_operate">
                                    <button @click="openSet(row)">编辑</button>
                                </div>
                                <div class="_delete">
                                    <button @click="del(row)">删除</button>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 添加或编辑一级分类弹框 -->
        <el-dialog
            :title="form.id ? '编辑' : '添加'"
            :visible.sync="dialogShow"
            width="40%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="80px">
                <el-form-item label="名字">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input v-model.trim="form.intro"></el-input>
                </el-form-item>
                <el-form-item label="链接">
                    <el-input v-model.trim="form.url"></el-input>
                </el-form-item>
                <el-form-item label="标签">
                    <el-input v-model.trim="form.tag"></el-input>
                </el-form-item>
                <el-form-item label="背景图">
                    <upload-img :src="form.bg" @removeSrc="form.bg = ''" ref="bg"></upload-img>
                </el-form-item>
                <el-form-item label="置顶">
                    <el-switch
                        class="sw"
                        v-model="form.is_top"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="是"
                        inactive-text="否"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import UploadImg from "@/components/customs/uploadImg.vue";

export default {
    components: { UploadImg },
    data() {
        return {
            tableData: [],
            // 一级分类表单
            form: {},
            dialogShow: false,
        };
    },
    async created() {
        this.getData();
    },
    methods: {
        // 获取列表数据
        async getData() {
            let { data } = await axios.get("/api/solution/shoplist");
            this.tableData = data;
        },
        async yes() {
            let { form } = this;
            form.bg = this.$refs.bg.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.post("/api/solution/shopedit", qs.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/solution/shopadd", qs.stringify(form));
            }
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            this.getData();
            this.dialogShow = false;
        },
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        openAdd() {
            this.form = {
                title: "",
                intro: "",
                url: "",
                tag: "",
                bg: "",
                is_top: 0,
            };
            this.dialogShow = true;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/solution/shopdel", { params: { id } });
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
// /deep/.el-switch__label.is-active {
//     color: #7781f1;
// }
.sw {
    /deep/.is-active {
        color: #7781f1 !important;
    }
}
</style>
